body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mainLayout {
  background: rgb(0,0,0);
  background: linear-gradient(145deg, rgba(0,0,0,1) 52%, rgba(174,149,114,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ae9572 !important; 
  /* font-weight: 500; */
}

.ant-tabs-tab {
  color: #8d8d8d !important;
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #ae9572;
}